import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'

import StyledBackgroundHero from '../components/background-header'
import Button from '../components/button'
import { CenterTextDiv } from '../components/common'
import Icon from '../components/icon'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Badge = styled.span`
  background-color: rgb(0, 56, 47);
  padding: 3px 5px;
  font-size: 12px;
  font-weight: bold;
  color: white;
`

const Line = styled.hr`
  background-color: black;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 24px !important;
  opacity: 0.3
`

const DownloadButton = Button.withComponent('a')

const PowerYoga = ({ data }) => (
  <Layout
    hero={
      <StyledBackgroundHero imageData={data.desktop.childImageSharp.fluid} />
    }
  >
    <SEO title="Hatha Yoga intense mit Ina Kaya" />

    <CenterTextDiv>
      <Badge>NEU</Badge>
      <br />
      <br />
      <h2>Hatha Yoga intense</h2>
      <br />
      <p>
      Neben kraftvollen Asanas (Yogastellungen) besteht die Hatha Yogastunde aus Tiefenentspannung, Meditation und Pranayama (Atemübungen). 
      Ein wundervoller Ausgleich zwischen Kraft, Entspannung und Konzentration. Perfekt um Körper, Geist und Seele wieder in Form zu bringen. Trau dich und probiere es einfach mal aus. Die Stunde ist für alle Yogaübende geeignet, die keine körperlichen Einschränkungen haben. Egal ob Anfänger, Mittelstufe oder Fortgeschrittene. 
      </p>

      <p>
      <b>Jeden Freitag 16:30 Uhr bis 18:00 Uhr </b>
      </p>

      <p>
        <h3>Preise</h3>
        Einzelstunde: 15€
        <br />
        Probestunde: kostenlos
        <br />
        10er Karte: 120€ (ganzjährig gültig)
        <br />
        <Line />
        Yogalehrerin: Ina Kaya
      </p>

      <DownloadButton href={data.anmeldungPdf.file.url} target="_blank">
        <div>Anmeldeformular herunterladen</div>
        <Icon style={{ marginLeft: 10 }} name="pdf" width={40} />
      </DownloadButton>

      <br />

      <p>
        Du hast Lust dabei zu sein? Dann schicke das ausgefüllte Anmeldeformular
        per Mail an:{' '}
        <a href="mailto:Ina.hilz@web.de?subject=Interesse an Power Yoga">
          ina.hilz@web.de
        </a>
      </p>

      <p>Ich freue mich auf Dich!</p>
    </CenterTextDiv>
  </Layout>
)

export default PowerYoga

export const query = graphql`
  query {
    anmeldungPdf: contentfulAsset(
      contentful_id: { eq: "3jHfSGzwV4jJPSDaVvIWp8" }
    ) {
      id
      file {
        url
      }
      description
    }

    desktop: file(relativePath: { eq: "power_yoga_02.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
